const getDataFromLocalStorage = () => {
  const jsonData = localStorage.getItem('responseData');
  return jsonData ? JSON.parse(jsonData) : null; // Devuelve null si no hay datos en el localStorage
};

const savedData = getDataFromLocalStorage();
const account = {
  displayName: savedData?.name || 'ValorPorDefecto', // Si savedData no existe o no tiene displayName, utiliza 'ValorPorDefecto'
  email: savedData?.email || 'ValorPorDefecto', // Si savedData no existe o no tiene email, utiliza 'ValorPorDefecto'
  photoURL: savedData?.logo || 'ValorPorDefecto', // Si savedData no existe o no tiene photoURL, utiliza 'ValorPorDefecto'
};

export default account;
