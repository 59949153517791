// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'inicio',
    path: '/dashboard/app',
    icon: icon('ic_home'),
  },
  {
    title: 'Usuarios Bethel',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'galeria de postcast',
    path: '/dashboard/postcast',
    icon: icon('ic_recoder'),
  },
  {
    title: 'galeria de videos',
    path: '/dashboard/videos',
    icon: icon('ic_video'),
  },
  {
    title: 'Libros y textos',
    path: '/dashboard/eventos',
    icon: icon('ic_book'),
  },
  {
    title: 'Eventos',
    path: '/dashboard/noticias',
    icon: icon('ic_event'),
  },
];

export default navConfig;
