import { useState,useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {

  function isAuthenticated() {
    return localStorage.getItem('isAuthenticated') === 'true';
  }
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://swserviceapp.mac-softins.com/api/ingresar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('responseData', JSON.stringify(responseData));
        navigate('/dashboard/app');
      } else {
        // Manejar error de autenticación
        console.error('Error de autenticación');
      }
    } catch (error) {
      console.error('Error de red', error);
    }
  };

  const verifyuser = async () => {
     const login=isAuthenticated();
     if(login===true){
        navigate('/dashboard/app');
     }else{
       console.log('tiene que logearse');
     }

     // Agrega aquí cualquier lógica que desees ejecutar automáticamente al montar el componente
   };
  useEffect(() => {
    verifyuser();
  }, []);
  return (
    <form>
      <Stack spacing={3}>
        <TextField name="email" label="Direccion Email" value={credentials.email} onChange={handleChange} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={credentials.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                 ________________________________________
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Ingresar
      </LoadingButton>
    </form>
  );
}
