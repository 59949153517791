import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useState,useEffect  } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------
export default function DashboardAppPage() {
  const [apiData, setApiData] = useState([]);
  const theme = useTheme();
  const getDataFromLocalStorage = () => {
    const jsonData = localStorage.getItem('responseData'); // Cambia 'getdata' por el nombre con el que guardaste los datos
      const data = JSON.parse(jsonData); // Parsear la cadena JSON a un objeto JavaScript
      return data;
  };

  const savedData = getDataFromLocalStorage();
  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch("https://swserviceapp.mac-softins.com/api/dashboardhome");
      if (response.ok) {
        const data = await response.json();
        setApiData(data); // Actualiza los datos de la API
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      console.error("Error al obtener datos de la API:", error);
    }
  };
  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  console.log(apiData)
  return (
    <>
      <Helmet>
        <title> Dashboard Bethel</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5, textAlign: 'center'  }} >
          Bienvenido, {savedData.name}
        </Typography>

        <Grid container spacing={3}>
          {apiData.map((data) => (
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title={data.name} total={data.cant} color={data.color} icon={data.icon} />
            </Grid>
          ))}

        </Grid>
      </Container>
    </>
  );
}
