import { useState,useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {

  function isAuthenticated() {
    return localStorage.getItem('isAuthenticated') === 'true';
  }
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };
  const kirito =localStorage.getItem('responseData')
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://swserviceapp.mac-softins.com/api/ingresar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('responseData', JSON.stringify(responseData));

      } else {
        // Manejar error de autenticación
        console.error('Error de autenticación');
      }
    } catch (error) {
      console.error('Error de red', error);
    }
  };

  const [userData, setUserData] = useState(null);
  const handleChangeCurrentPassword = (e) => {
    // Manejar cambios en el campo de contraseña actual
    const newCurrentPassword = e.target.value;
    // Realiza cualquier acción que necesites con el nuevo valor de la contraseña actual
  };

  const handleChangeNewPassword = (e) => {
    // Manejar cambios en el campo de nueva contraseña
    const newNewPassword = e.target.value;
    // Realiza cualquier acción que necesites con el nuevo valor de la nueva contraseña
  };

  const handleChangeConfirmPassword = (e) => {
    // Manejar cambios en el campo de confirmación de contraseña
    const newConfirmPassword = e.target.value;
    // Realiza cualquier acción que necesites con el nuevo valor de la confirmación de contraseña
  };
  const resetpassword=()=>{
    
  }
  useEffect(() => {
    // Obtén los datos del Local Storage al cargar el componente
    const userData = localStorage.getItem('responseData');
    if (userData) {
      // Analiza los datos si es un objeto JSON
      const parsedData = JSON.parse(userData);
      setUserData(parsedData);
    }
  }, []);
  return (
    <form>
    <Stack spacing={3}>
      <TextField name="email" label="Direccion Email" value={userData && userData.email}  />
      <TextField name="name" label="Nombre completo" value={userData && userData.name}  />
      <TextField name="cedula" label="Cedula de identidad" value={userData && userData.ci}  />

      <TextField
        name="currentPassword"
        label="Contraseña Actual"
        type={showPassword ? 'text' : 'password'}
        value={credentials.currentPassword || undefined}
        onChange={handleChangeCurrentPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}

      />
      <TextField
        name="newPassword"
        label="Nueva Contraseña"
        type={showPassword ? 'text' : 'password'}
        value={credentials.newPassword || undefined}
        onChange={handleChangeNewPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}

      />
      <TextField
        name="confirmPassword"
        label="Repita Contraseña"
        type={showPassword ? 'text' : 'password'}
        value={credentials.confirmPassword || undefined}
        onChange={handleChangeConfirmPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}

      />
    </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                 ________________________________________
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={resetpassword}>
        Cambiar mi contraseña
      </LoadingButton>
    </form>
  );
}
